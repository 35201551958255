/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    watch: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.5 5a.5.5 0 00-1 0v2.5H6a.5.5 0 000 1h2a.5.5 0 00.5-.5z"/><path pid="1" d="M5.667 16C4.747 16 4 15.254 4 14.333v-1.86A6 6 0 012 8c0-1.777.772-3.374 2-4.472V1.667C4 .747 4.746 0 5.667 0h4.666C11.253 0 12 .746 12 1.667v1.86a6 6 0 011.918 3.48.502.502 0 01.582.493v1a.5.5 0 01-.582.493A6 6 0 0112 12.473v1.86c0 .92-.746 1.667-1.667 1.667zM13 8A5 5 0 103 8a5 5 0 0010 0"/>',
    },
});
